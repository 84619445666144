
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.b {
    --gap: #{spacer(2)};

    &.-radius {
        --border-radius: calc(0.75 * var(--grid-gutter));
    }
}

.b_item {}

