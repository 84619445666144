/*=======================================
=            Global settings            =
=======================================*/


/*----------  Colors  ----------*/

$color-darkest      : #000000;
$color-dark         : #0B130D;
$color-light        : #FFFFFF;
$color-gray         : #909090;

$color-green        : #D1E2C0;
$color-yellow       : #D2B13D;
$color-red          : #D95F45;
$color-blue         : #326FE8;

$color-primary      : $color-green;

$font-color         : $color-darkest;
$selection-bg       : $color-darkest;
$selection-color    : $color-light;


/*----------  Typography  ----------*/

$font-families: (
    "sans": ("Neue Montreal", "Helvetica Neue", Arial, sans-serif),
    "serif": ("GT Alpina", Georgia, serif)
);

$font-size: 16px;
$line-height: 1.5;

$font-dir: "/static/fonts/";

$fontfaces: (
    "Neue Montreal" "Neue-Montreal-Regular" 400 normal,
    // "GT Alpina" "GT-Alpina-Thin" 200 normal,
    "GT Alpina" "GT-Alpina-Thin-Italic" 200 italic,
    "GT Alpina" "GT-Alpina-Light" 300 normal,
    "GT Alpina" "GT-Alpina-Light-Italic" 300 italic,
);


/*=================================================
=            Media queries breakpoints            =
=================================================*/


$breakpoints: (
    "xxs":     0,
    "xs":    480,
    "sm":    768,
    "md":    992,
    "lg":   1280,
    "xl":   1440,
    "xxl":  1920
);



/*============================
=            Grid            =
============================*/


// Spacing
$unit: .5rem;

$grid-width: 100%;
$grid-max-width: 100%; // 1280px
$grid-columns: 12;
$grid-gutters: (
    "xxs"   : $unit,
    "xs"    : $unit,
    "sm"    : $unit,
    "md"    : $unit,
    "lg"    : $unit,
    "xl"    : $unit,
    "xxl"   : $unit
);


/*===============================
=            Spacers            =
===============================*/


$spacers: (
    0.5   : 0.5,
    1     : 1,
    2     : 1.5,
    3     : 2,
    4     : 3,
    5     : 4.5,
    6     : 6,
    7     : 7.5,
    8     : 11.25,
    9     : 15
);



/*===========================================
=            Images and SVG dirs            =
===========================================*/


$img-dir: "/img/layout/";
$svg-dir: "/assets/svg/layout/";



/*=================================
=            Z-indexes            =
=================================*/


$layers: (
    "goku"     : 9000,
    "modal"    : 200,
    "dropdown" : 100,
    "default"  : 1,
    "limbo"    : -999
);


/*===================================
=            Transitions            =
===================================*/


/*----------  Speeds  ----------*/

$speed:               0.4s;
$speed-fast:          ($speed * 0.5);
$speed-medium:        ($speed * 1.5);
$speed-slow:          ($speed * 2);
$speed-medium-slow:   ($speed * 2.5);
$speed-slower:        ($speed * 3);
$speed-slower-medium: ($speed * 3.5);
$speed-slowest:       ($speed * 4);
$speed-loader:        $speed-slower;


/*----------  Easing  ----------*/

$in-quad: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$out-quad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$in-out-quad: cubic-bezier(0.455, 0.030, 0.515, 0.955);

$in-cubic: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$out-cubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);

$in-quart: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$out-quart: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$in-out-quart: cubic-bezier(0.770, 0.000, 0.175, 1.000);

$in-quint: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$out-quint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$in-out-quint: cubic-bezier(0.860, 0.000, 0.070, 1.000);

$in-sine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$out-sine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$in-out-sine: cubic-bezier(0.445, 0.050, 0.550, 0.950);

$in-expo: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$out-expo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$in-out-expo: cubic-bezier(1.000, 0.000, 0.000, 1.000);

$in-circ: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$out-circ: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$in-out-circ: cubic-bezier(0.785, 0.135, 0.150, 0.860);

$in-back: cubic-bezier(0.600, -0.280, 0.735, 0.045);
$out-back: cubic-bezier(0.175, 00.885, 0.320, 1.275);
$in-out-back: cubic-bezier(0.680, -0.550, 0.265, 1.550);

$out-back-t: cubic-bezier(0.535, 1.650, 0.635, 1.005);

$smooth-in  : cubic-bezier(0.3, 1.0, 1.0, 1.0);
$smooth-out : cubic-bezier(0.0, 0.0, 0.7, 1.0);
$smooth     : cubic-bezier(0.3, 1.0, 0.7, 1.0);
// $smooth: cubic-bezier(0.380, 1, 0.215, 1);

$easing     : $smooth;
$in-easing  : $smooth-in;
$out-easing : $smooth-out;



/*============================
=            Misc            =
============================*/


// Accessibility
$accessible-size: 48px;
