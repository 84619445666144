
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.o-at {
    --at-lines-count: 1;
    --at-line-index: 0;
    --at-words-count: 1;
    --at-word-index: 0;

    --at-duration-in: 1s;
    --at-easing-in: #{$easing};
    --at-delay-in: 0s;

    --at-duration-out: calc(.5 * var(--at-duration-in));
    --at-easing-out: #{$easing};
    --at-delay-out: 0s;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    p,
    br {
        width: 100%;
    }

//    * {
//        line-height: inherit;
//        font-size: inherit;
//        font-weight: inherit;
//    }

    &.-center {
        justify-content: center;
    }

    &.-right {
        justify-content: right;
    }

    // Lines and words
    &.-lines {

        .o-at_l {
            opacity: 0;
            transform: translate(0, 100%);
            will-change: opacity, transform;

            transition-property: opacity, transform;
            transition-duration: var(--at-duration-out);
            transition-timing-function: var(--at-easing-out);
            // transition-delay: calc(var(--at-duration-out)/2 * ((var(--at-lines-count) - var(--at-line-index))/var(--at-lines-count)) + var(--at-delay-out));
            transition-delay: calc(var(--at-line-index) * var(--at-duration-out)/4 + var(--at-delay-out));
        }
    }

    &.-words {

        .o-at_w {
            opacity: 0;
            transform: translate(0, 100%);
            will-change: opacity, transform;

            transition-property: opacity, transform;
            transition-duration: var(--at-duration-out);
            transition-timing-function: var(--at-easing-out);
            // transition-delay: calc(var(--at-duration-out)/2 * ((var(--at-words-count)/10 - var(--at-word-index))/10) + var(--at-delay-out));
            transition-delay: calc(var(--at-word-index) * var(--at-duration-out)/15 + var(--at-delay-out));
        }

        .o-icon {
            opacity: 0;
            transform: translate(0, 100%) rotateY(90deg);

            transition-property: opacity, transform;
            transition-duration: var(--at-duration-out);
            transition-timing-function: var(--at-easing-out);
            // transition-delay: calc(var(--at-duration-out)/2 * ((var(--at-words-count)/10 - var(--at-word-index))/10) + var(--at-delay-out));
            transition-delay: calc(var(--at-word-index) * var(--at-duration-out)/15 + var(--at-delay-out));

            @for $i from 1 through 25 {

                &:nth-child(#{$i}) {
                    --at-word-index: #{$i - 1};
                }
            }
        }
    }

    // Block
    &.-block {
        opacity: 0;
        transform: translate(0, 2em);

        transition-property: opacity, transform;
        transition-duration: var(--at-duration-out);
        transition-timing-function: var(--at-easing-out);
        transition-delay: var(--at-delay-out);
    }


    html.is-ready &.is-visible {

        // Lines
        &.-lines {

            .o-at_l {
                opacity: 1;
                transform: translate(0);

                transition-timing-function: var(--at-easing-in);
                transition-duration: var(--at-duration-in);
                // transition-delay: calc(var(--at-duration-in)/2 * (var(--at-line-index)/var(--at-lines-count)) + var(--at-delay-in));
                transition-delay: calc(var(--at-line-index) * var(--at-duration-in)/4 + var(--at-delay-in));
            }
        }

        &.-words {

            .o-at_w {
                opacity: 1;
                transform: translate(0);

                transition-timing-function: var(--at-easing-in);
                transition-duration: var(--at-duration-in);
                // transition-delay: calc(var(--at-duration-in)/2 * (var(--at-word-index)/10) + var(--at-delay-in));
                transition-delay: calc(var(--at-word-index) * var(--at-duration-in)/15 + var(--at-delay-in));
            }

            .o-icon {
                opacity: 1;
                transform: translate(0) rotateY(0);

                transition-timing-function: var(--at-easing-in);
                transition-duration: calc(1.25 * var(--at-duration-in));
                // transition-delay: calc(var(--at-duration-in)/2 * (var(--at-word-index)/10) + var(--at-delay-in));
                transition-delay: calc(var(--at-word-index) * var(--at-duration-in)/15 + var(--at-delay-in));
            }
        }

        // Block
        &.-block {
            opacity: 1;
            transform: translate(0);

            transition-timing-function: var(--at-easing-in);
            transition-duration: var(--at-duration-in);
            transition-delay: var(--at-delay-in);
        }
    }
}

.o-at_l {
    overflow: hidden;

    .o-at.t-link & {
        padding-right: .25em;
    }
}

.o-at_w {
}

.o-at_c {}


