
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.p-projects-grid {
    padding-top: spacer(2);
}

.p-projects-grid_filters {

    @media #{md("xs")} {
        width: grid-space(2/3);
    }

    @media #{md("lg")} {
        width: grid-space(1/2);
    }

    &.is-disabled {
        pointer-events: none;
    }
}

.p-projects-grid_list {
    @include reset-list;

    display: flex;
    flex-wrap: wrap;
    gap: spacer(4) var(--grid-padding);
    margin-top: spacer(3);
    counter-reset: card-counter;
}

.p-projects-grid_item {
    counter-increment: card-counter;
    will-change: transform;

    @media #{md("sm", "max")} {
        width: 100%;
    }

    @media #{md("sm")} {
        width: grid-space(1/2);
    }
}


