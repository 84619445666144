
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.l-footer {
    // margin-top: spacer(9);
    padding-top: spacer(9);
}

.l-footer_inner {
    display: flex;
    flex-wrap: wrap;
    gap: spacer(4) var(--grid-gutter);

    @media #{md("sm")} {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.l-footer_col {
    width: 100%;

    &.-hours {
        display: grid;
        grid-gap: spacer(2) var(--grid-gutter);
        grid-template-columns: grid-space(5/12) grid-space(7/12);
        align-items: center;
    }

    &.-address {

        .o-icon {
            display: block;
            margin-bottom: spacer(1);
        }
    }

    @media #{md("xs")} {

        &.-hours {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    @media #{md("sm")} {
        width: grid-space(1/2);

        &.-address:nth-child(3) {
            width: auto;
            margin-left: grid-space(3/12, 1);
        }
    }
}

.l-footer_clock {

    @media #{md("sm", "max")} {
        width: grid-space(5/6);
    }

    @media #{md("xs", "max")} {
        width: grid-space(4/5);
    }
}

.l-footer_headline {
    margin-bottom: spacer(2);
}

.l-footer_contact {
    counter: footer-contact;
    @include reset-list;

    li {
        counter-increment: footer-contact;

        &:not(:first-child) {
            margin-top: spacer(.5);
        }
    }

    a {
        // display: flex;
        // align-items: flex-start;
        // width: 100%;
        padding-left: 1.5rem;

        &:before {
            content: counters(footer-contact, "", decimal-leading-zero);
            position: absolute;
            top: .5em;
            left: 0;
            font-size: 1rem;
            pointer-events: none;
        }
    }
}

.l-footer_address {
    display: block;
}

.l-footers_hours {
    display: flex;
    flex-direction: column;

    > .o-icon {
        margin-top: 1em;
        margin-bottom: 1em;
    }
}

.l-footer_bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: spacer(7);
    padding-bottom: var(--grid-gutter);
}

.l-footer_logo {
    display: block;
    width: 100%;

    svg {
        display: block;
        width: 100%;
        fill: $color-primary;
    }

    path {
        display: block;
    }
}

.l-footer_copy {
    --at-duration-in: .5s;
    --at-duration-out: .5s;

    margin-top: spacer(2);
    font-size: .75em;
    // opacity: 0;
    // transform: translate(0, 100%);
    // transition: opacity $speed $easing, transform $speed $easing;

    // &.is-visible {
    //     opacity: 1;
    //     transform: translate(0);
    // }
}

