
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.c-team {
    @include reset-list;
    couter-reset: team-member;
    display: grid;

    @media #{md("sm")} {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.c-team_member {
    counter-increment: team-member;
    padding: spacer(2) var(--grid-gutter-half);
}

.c-team_inner {

    &:before {
        content: counters(team-member, "", decimal-leading-zero);
        position: absolute;
        top: .75em;
        left: -1.5em;
        display: inline-block;
        font-size: .75em;
    }
}

.c-team_job,
.c-team_name {
    display: block;
    font-size: inherit;
    font-weight: inherit;
}

.c-team_desc {}

.c-team_photo {
    z-index: 2000;
    position: absolute;
    top: -12em;
    left: -12em;
    width: 24em;
    height: 24em;
    pointer-events: none;

    canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transform: scale(0);
    }
}


