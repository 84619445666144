
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.c-btn {
    --btn-bg-color      : transparent;
    --btn-border-color  : currentColor;
    --btn-text-color    : currentColor;

    display: inline-flex;
    padding: .3em .7em;
    color: var(--btn-text-color);
    background-color: var(--btn-bg-color);
    border-radius: 3em;
    user-select: none;

    // Pointer cursor
    @at-root a#{&}      { cursor: pointer }
    @at-root button#{&} { cursor: pointer }

    // Modifiers
    &.-dark {
        --btn-bg-color      : #{$color-dark};
        --btn-border-color  : #{$color-dark};
        --btn-text-color    : #{$color-light};
    }

    &.-light {
        --btn-bg-color      : transparent;
        --btn-border-color  : #{$color-dark};
        --btn-text-color    : #{$color-dark};
    }

    &.-small {
        padding: .2em .4em;
    }

    &.is-active {
        --btn-bg-color      : #{$color-dark};
        --btn-border-color  : var(--btn-bg-color);
        --btn-text-color    : #{$color-light};
    }
}

.c-btn_bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--btn-bg-color);
    border: 2px solid var(--btn-border-color);
    border-radius: inherit;
}

.c-btn_inner {
    display: flex;
    align-items: center;
}

.c-btn_label {
    line-height: 1;
    font-size: 20px;
    text-transform: uppercase;

    & + .c-btn_icon {
        margin-left: 1em;
    }

    .c-btn.-small & {
        font-size: 1em;
    }

    @media #{md("lg")} {
        font-size: 32px;
    }
}

.c-btn_icon {
    flex-shrink: 0;

    & + .c-btn_label {
        margin-left: 1em;
    }
}

