
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.p-about {
    --root-color    : #{$color-light};
    --root-bg       : #{$color-dark};
}

.p-about_title {

    .o-icon {
        margin-right: .5em;
    }
}

.p-about_heading {

    .o-icon,
    .t-highlight {
        color: var(--root-color-primary);
    }

    .o-icon {
        margin-right: .15em;
        margin-left: .15em;
        color: var(--root-color-primary);
    }

    .o-at {

        &:nth-child(1) {

            .o-at_l .t-highlight:first-child .o-at_w:first-child {
                text-transform: capitalize;
            }
        }

        &:nth-child(2) {
            --at-delay-in: 1.8s;
        }

        &:nth-child(3) {
            --at-delay-in: 3s;
        }
    }

    .o-at_l {
        overflow: hidden;
    }

    @media #{md("sm")} {

        .o-at {

            &:not(:first-child) {
                width: 100%;
                padding-top: spacer(4);
            }

            &:nth-child(2) {
                padding-left: grid-space(3/12);
                text-align: right;
            }

            &:nth-child(3) {
                width: grid-space(8/12);
                margin-left: grid-space(2/12, 1);
            }
        }
    }
}


/*=============================
=            Intro            =
=============================*/

.o-intro {
    display: flex;
    gap: spacer(2) var(--grid-gutter);

    @media #{md("sm", "max")} {
        flex-direction: column;
    }

    @media #{md("sm")} {
        align-items: flex-start;
        justify-content: space-between;
        padding-right: grid-space(1/12, 1);
        padding-left: grid-space(1/12, 1);
    }

    @media #{md("md")} {
        padding-left: grid-space(2/12, 1);
    }
}

.o-intro_body {

    @media #{md("xs")} {
        padding-right: grid-space(1/6, 1);
    }

    @media #{md("sm")} {
        width: grid-space(7/10);
        padding-right: grid-space(1/10, 1);
        padding-left: 0;
    }

    @media #{md("md")} {
        width: grid-space(7/9);
        padding-right: grid-space(1/9, 1);
    }
}

.o-intro_list {
    counter-reset: intro-item;
    font-family: ff("serif");

    @media #{md("sm", "max")} {
        display: flex;
        flex-wrap: wrap;
        gap: spacer(2) var(--grid-gutter);
    }

    @media #{md("sm")} {
        width: grid-space(3/10);
    }

    @media #{md("md")} {
        width: grid-space(2/9);
    }
}

.o-intro_item {
    counter-increment: intro-item;

    @media #{md("sm", "max")} {
        width: grid-space(1/3);
        padding-right: 1em;
    }

    @media #{md("xs", "max")} {
        width: grid-space(1/2);
    }

    @media #{md("sm")} {

        &:not(:first-child) {
            margin-top: spacer(3);
        }
    }
}

.o-intro_label {
    margin-bottom: spacer(1);
    line-height: 1;
    font-weight: 700;

    &:before {
        content: counter(intro-item);
        position: absolute;
        bottom: .1em;
        left: calc(-.75 * var(--grid-gutter));
        font-size: .75em;
        font-family: ff("sans");
        font-weight: 400;
    }

    @media #{md("sm")} {

        &:before {
            left: -2em;
        }
    }
}
.o-intro_desc {
    font-weight: 200;
}



/*================================
=            Services            =
================================*/

.o-services {
    display: grid;
    grid-gap: spacer(4) var(--grid-gutter);

    @media #{md("xs")} {
        grid-template-columns: grid-space(1/2) grid-space(1/2);
    }

    @media #{md("md")} {
        grid-template-columns: grid-space(1/4) grid-space(1/4) grid-space(1/4) grid-space(1/4);
    }
}

.o-services_items {
}

.o-services_title {
    margin-bottom: spacer(2);
    font-weight: 400;
    text-transform: uppercase;
}

.o-services_list {
    @include reset-list;
    display: grid;
}

.o-services_item {

    &:not(:first-child) {
        margin-top: .25em;
    }
}



