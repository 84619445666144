
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.o-loader {
    z-index: 500;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    html.is-ready & {
        left: 200vw;
        transition: left 0s var(--loader-duration-out);
    }
}

.o-loader_bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $color-dark;
    will-change: transform;

    html.is-loading & {
        transform-origin: 50% 0;
        transform: scale(1);
        transition: transform var(--loader-duration-in) var(--loader-ease-in);
    }

    html.is-ready & {
        transform: scale(1, 0);
        transform-origin: 50% 100%;
        transition: transform var(--loader-duration-out) var(--loader-ease-out);
    }
}

.o-loader_lottie {
    width: 50vmax;
    opacity: 0;
    transform: translate(0, 2em);
    transition: opacity var(--loader-duration-out) var(--loader-ease-out), transform var(--loader-duration-out) var(--loader-ease-out);

    html.is-loading & {
        opacity: 1;
        transform: translate(0);
    }
}

.o-loader_logo {
    --logo-bg       : #{$color-primary};
    --logo-letters  : #{$color-dark};
    --logo-smiley   : transparent;

    display: inline-block;
    pointer-events: none;
    opacity: 0;
    transform: scale(0);
    transition: opacity var(--loader-duration-out) var(--loader-ease-out), transform var(--loader-duration-out) var(--loader-ease-out);

    .o-icon {
        animation: anim-loader-logo 4s infinite linear;
    }

    .svg-logo {
        --icon-width: 10vmax;
    }

    html.is-loading & {
        opacity: 1;
        transform: scale(1);
        transition: transform var(--loader-duration-in) var(--loader-ease-in);
    }

    html.is-ready & {

        .o-icon {
            animation-play-state: paused;
        }
    }

    @media #{md("xl")} {

        .svg-logo {
            --icon-width: 7vmax;
        }
    }
}

/*----------  Logo animation  ----------*/

@keyframes anim-loader-logo {
    0%      { transform: rotate(0) }
    100%    { transform: rotate(1turn) }
}


