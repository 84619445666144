/*=================================
=            Selection            =
=================================*/


::-moz-selection {
	text-shadow: none;
	color: var(--root-bg);
	background: var(--root-color);
}

::selection {
	text-shadow: none;
	color: var(--root-bg);
	background: var(--root-color);
}



/*=======================================
=            Global settings            =
=======================================*/

:root {
    --unit: 1rem;
    --grid-gutter: calc(0.5 * var(--unit));
    --grid-gutter-half: calc(.5 * var(--grid-gutter));
    --grid-padding: calc(1.5 * var(--unit));
    --header-height: calc(2 * var(--grid-padding) + 4em);

    // Theme
    --theme-color-darkest   : #{$color-darkest};
    --theme-color-dark      : #{$color-dark};
    --theme-color-light     : #{$color-light};
    --theme-color-gray      : #{$color-gray};
    --theme-color-primary   : #{$color-primary};

    // Loader
    --loader-duration-in    : #{$speed-slow};
    --loader-ease-in        : #{$easing};
    --loader-duration-out   : #{$speed-medium};
    --loader-ease-out       : #{$easing};

    // Colors
    --root-color            : var(--theme-color-dark);
    --root-color-primary    : var(--theme-color-primary);
    --root-bg               : var(--theme-color-light);


    @media #{md("xs")} {
        --grid-gutter: calc(1 * var(--unit));
        --grid-padding: var(--grid-gutter);
    }

    @media #{md("sm")} {
        --grid-gutter: calc(1.5 * var(--unit));
    }

    @media #{md("lg")} {
        --grid-gutter: calc(2 * var(--unit));
    }
}

*,
*:after,
*:before {
	position: relative;
	outline: none;
}


html {
	font-size: $font-size - 3;

    // Media queries
    @media #{md("sm")} {
   	    font-size: $font-size - 2;
    }

    @media #{md("md")} {
   	    font-size: $font-size - 1;
    }

    @media #{md("lg")} {
        font-size: $font-size;
    }

    @media #{md("xl")} {
        font-size: $font-size + 2;
    }

    @media #{md("xxl")} {
        font-size: $font-size + 4;
    }

    // Better font rendering for mac
    @include antialiased;
}
