
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                



.o-asset {
    display: block;
    width: 100%;
    overflow: hidden;

    @at-root a#{&} {

        > * {
            transition: transform $speed-medium $easing;
        }

        &:hover {

            > * {
                transform: scale(1.25);
                transition: transform 5s ease-in-out;
            }
        }
    }

    &.-cover,
    &.-ratio {
        @include asset-fill;
    }

    &.-ratio {
        --asset-ratio: 16/9;

        height: 0;
        padding-top: calc(100% * var(--asset-ratio));

        > * {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
