
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.o-image {
    display: block;

    &.-lazy {
        overflow: hidden;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: currentColor;
        opacity: .1;
    }
}

.o-image_img {
    @include img;

    .o-image.-reveal & {
        opacity: 0;
        transform: scale(1.1);
    }

    .o-image.-reveal.is-inview:not(.-lazy) &,
    .o-image.-reveal.is-inview.-lazy.is-loaded & {
        opacity: 1;
        transform: scale(1);
        transition: all $speed-slow $easing;
    }
}

