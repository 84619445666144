
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.b-theater {
    padding-top: spacer(8);
    padding-bottom: spacer(8);
    background-color: var(--b-theater-bg);
}

.b-theater_inner {
}

.b-theater_asset {

    &.-large {
        width: 100%;
    }

    &.-fullscreen {
        width: 100vw;
        margin-right: calc(-1 * var(--grid-gutter));
        margin-left: calc(-1 * var(--grid-gutter));
    }

    @media #{md("xs")} {
        padding-right: grid-space(1/12, 1);
        padding-left: grid-space(1/12, 1);

        &.-half {
            width: grid-space(1/2);
        }
    }
}

.b-theater_content {
    padding-top: spacer(4);

    @media #{md("xs")} {
        margin-right: grid-space(1/12, 1);
        margin-left: grid-space(1/12, 1);
    }

    @media #{md("sm")} {
        width: grid-space(1/2);
    }

    @media #{md("md")} {
        width: grid-space(1/3);
    }
}

.b-theater_title {

    & + .b-theater_body {
        margin-top: spacer(1);
    }
}

