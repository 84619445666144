
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.p-home-grid {
    @include reset-list;
    display: flex;
    flex-wrap: wrap;
    gap: spacer(6) var(--grid-padding);
    counter-reset: card-counter;
}

.p-home-grid_item {
    counter-increment: card-counter;

    &:first-child {
        width: 100% !important;
        margin-left: 0 !important;
    }

    @media #{md("sm", "max")} {

        &:not(:first-child) {
            margin-top: spacer(4);
        }

        &.-with-definition {
            width: 100%;

            .c-card {
                width: grid-space(2/3);
            }

            .p-home_definition {
                width: grid-space(1/3);
                margin-top: spacer(4);
                margin-left: grid-space(2/3, 1);
            }

            &:nth-child(6) {

                .c-card {
                    margin-left: auto;
                }

                .p-home_definition {
                    margin-left: 0;
                }
            }
        }

        .c-card_cover .o-asset {
            --asset-ratio: 3/4 !important;
        }
    }

    @media #{md("xs", "max")} {
        width: 100%;

        &.-with-definition {

            .c-card {
                width: 100%
            }

            .p-home_definition {
                width: grid-space(1/2);
                margin-left: auto !important;
                margin-right: auto;
            }
        }
    }

    @media #{md("xs")} {
        width: grid-space(2/3);

        &:nth-child(3n) {
            margin-left: auto;
        }

        &:nth-child(3n+1) {
            margin-right: auto;
            margin-left: auto;
        }
    }

    @media #{md("sm")} {

        &.-with-definition {
            display: grid;
            align-items: center;

            &:nth-child(2) {
                width: grid-space(9/12);
                grid-column-gap: grid-space(1/9, 2);
                grid-template-columns: grid-space(6/9) grid-space(3/9);
            }

            &:nth-child(6) {
                width: grid-space(7/12);
                grid-column-gap: var(--grid-gutter);
                grid-template-columns: grid-space(4/7) grid-space(3/7);
            }
        }

        &:nth-child(6n+2) {
            width: grid-space(6/12);
        }

        &:nth-child(6n+3){
            margin-top: grid-space(1/5);
            margin-left: auto;
            width: grid-space(5/12)
        }

        &:nth-child(6n+4) {
            width: grid-space(5/12);
            // margin-top: grid-space(-1/8);
            margin-right: 0;
        }

        &:nth-child(6n+5) {
            width: grid-space(8/12);
            margin-top: grid-space(1/12);
            margin-left: grid-space(2/12, 1);
        }

        &:nth-child(6n) {
            width: grid-space(3/12);
            // margin-top: grid-space(1/8);
            margin-left: 0;
            // margin-left: grid-space(1/12, 1);
        }

        &:nth-child(6n+1) {
            width: grid-space(6/12);
            margin-right: 0;
        }
    }

    @media #{md("lg")} {

        &.-with-definition {

            &:nth-child(2) {
                grid-template-columns: grid-space(6/9) grid-space(2/9);
            }

            &:nth-child(6) {
                width: grid-space(6/12);
                grid-template-columns: grid-space(4/6) grid-space(2/6);
            }
        }
    }
}

.p-home_definition {

    .svg-shape-circle-lines {
        --icon-width: 2.5em;
    }
}


