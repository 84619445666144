
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.b-grid {
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--gap);
    margin-bottom: calc(-1 * var(--grid-padding));
}

.b-grid_item {
    width: 100%;
    margin-bottom: var(--gap);

    &.-no-margin {
        margin-bottom: 0;
    }

    &.-large {
        width: calc(100% - 2 * var(--gap));
        margin-right: auto;
        margin-left: auto;
    }

    &:not(.-fullscreen) {
        border-radius: var(--border-radius, 0);
        overflow: hidden;
    }

    @media #{md("xs", "max")} {

        &.-half {
            width: calc(100% - 2 * var(--gap));
            margin-right: auto;
            margin-left: auto;
        }
    }

    @media #{md("xs")} {

        &.-half {
            width: calc(50% - 1.5 * var(--gap));
            margin-left: var(--gap);
        }

        // &.-half {
        //     padding-right: 0;
        //     padding-left: 0;
        //     width: calc(50% - 1.25 * var(--gap));
        //     margin-left: var(--gap);
        //     margin-right: auto;
        //     // width: 50%;
        // }
    }
}

