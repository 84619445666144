
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.b-title {
    margin-top: spacer(3);
    margin-bottom: spacer(4);
}

.b-title_inner {

    @media #{md("xs", "max")} {
        text-indent: grid-space(1/6, 1);
    }

    @media #{md("xs")} {
        padding-right: grid-space(1/4);
        text-indent: grid-space(1/4);
    }

    @media #{md("md")} {
        padding-right: grid-space(1/3);
    }
}

