
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.p-project {}

.p-project_cover {
    height: vh(80);
}

.p-project_header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: spacer(4);
    margin-bottom: spacer(3);

    .o-categories {
        // width: 100%;
    }

    @media #{md("xs", "max")} {
        flex-direction: column;

        .o-categories {
            margin-top: var(--unit);
        }
    }

    @media #{md("xs")} {

        .o-categories {
            // justify-content: flex-end;
            flex-shrink: 0;
            margin-left: var(--grid-gutter);
        }
    }
}


/*----------  Project Nav  ----------*/

.o-project-nav {
    margin-top: spacer(8);
}

.o-project-nav_title {
    padding-bottom: spacer(7);
}

.o-project-nav_list {
    display: grid;

    @media #{md("xs", "max")} {
        grid-row-gap: spacer(4);
    }

    @media #{md("xs")} {
        grid-column-gap: grid-space(1/12, 2);
        grid-template-columns: grid-space(5/12) grid-space(5/12);
    }
}

.o-project-nav_item {
    display: flex;
    flex-direction: column;
}

.o-project-nav_arrow,
.o-project-nav_name,
.o-project-nav_headline {
    margin-bottom: spacer(1);
}

.o-project-nav_arrow {
    display: block;
}

.o-project-nav_name {

    sup {
        margin-left: .5em;
        text-transform: uppercase;
    }
}

.o-project-nav_cover {
    display: block;
    margin-top: auto;

    @media #{md("md")} {
        width: grid-space(3/5);
    }
}


