
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.c-jobs {
    margin-top: spacer(6);

    @media #{md("sm")} {
        display: grid;
        grid-gap: var(--grid-gutter);
        grid-template-columns: grid-space(6/12) grid-space(5/12);
    }
}

.c-jobs_title {
    color: $color-gray;

    @media #{md("sm", "max")} {
        margin-bottom: spacer(4);
    }
}

.c-jobs_list {
    @include reset-list;
}

.c-jobs_item {
}

.c-jobs_link.t-link {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}


