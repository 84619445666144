
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.o-analog-clock {}

.o-analog-clock_inner {
    width: 100%;
    height: 0;
    padding-top: 100%;
    opacity: 0;
    transform: scale(.97);
    will-change: opacity, transform;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        fill: var(--root-color);
        transition: fill $speed $easing;
    }

    .o-analog-clock.-invert & {

        svg {
            fill: var(--root-bg);
        }
    }
}

.o-analog-clock_bg {
    fill: var(--root-bg);
    transition: inherit;

    .o-analog-clock.-invert & {
        fill: var(--root-color);
    }
}

.o-analog-clock_outline {

    .o-analog-clock.-invert & {
        fill: none;
    }
}

