
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.c-card {
}

.c-card_cover {
    display: block;
    margin-bottom: spacer(1);
}

.c-card_categories {
    position: absolute;
    right: var(--unit);
    bottom: var(--unit);
}

.c-card_content {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: var(--grid-gutter);
}

.c-card_title {
    flex-shrink: 0;
    width: 100%;

    .c-card.-counter &:before {
        content: counters(card-counter, "", decimal-leading-zero);
        display: inline-block;
        vertical-align: top;
        padding-right: .5em;
        font-size: .5em;
        font-weight: 400;
    }
}

.c-card_headline {
    width: calc(50% + var(--grid-gutter-half));
    text-align: right;
}

.c-card_link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    user-select: none;
    opacity: 0;
    cursor: pointer;
}

