
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.l-grid-debugger {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    pointer-events: none;

    span {
        width: grid-space(1/12);
        background-color: rgba(#B8B8B8, .25);
    }

    &:not(.is-visible) {
        visibility: hidden;
    }
}

