
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.l-section {
    margin-bottom: spacer(6);

    &:first-child {
        padding-top: calc(var(--header-height) + spacer(2));
    }

    @media #{md("sm")} {
        margin-bottom: spacer(8);
    }
}

.l-section_inner {}

.l-section_header {
    margin-bottom: spacer(5);
}

