
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.b-content {
    margin-top: spacer(4);
    margin-bottom: spacer(8);
}

.b-content_headline {
    margin-bottom: spacer(3);
}

.b-content_grid {
    display: flex;
    flex-wrap: wrap;
    gap: var(--grid-gutter);
}

.b-content_col {
    width: var(--content-col-width, 100%);
    margin-left: var(--content-col-offset, 0%);

    &.-w-1\/4 {
        --content-col-width: #{grid-space(1/2)};
    }

    @media #{md("xs")} {

        &.-w-1\/2 {
            --content-col-width: #{grid-space(1/2)};
        }

        &.-w-1\/3 {
            --content-col-width: #{grid-space(1/3)};
        }

        &.-w-1\/4 {
            --content-col-width: #{grid-space(1/4)};
        }

        @for $i from 1 through 4 {
            &.-o-#{$i}\/6 {
                --content-col-offset: #{grid-space($i/6, 1)};
            }
        }
    }

    @media #{md("sm")} {

        &.-w-1\/4 {
            --content-col-width: #{grid-space(1/4)};
        }
    }
}


