
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.l-header {
}

.l-nav,
.l-header_logo {
    z-index: 100;
    position: fixed;
}

.l-header_logo {
    top: var(--grid-padding);
    left: var(--grid-padding);
    display: inline-block;
    will-change: transform;

    .p-project & {
        --logo-bg: transparent;
        --logo-smiley: transparent;
        --logo-letters: currentColor;
    }

    opacity: 0;
    transition: opacity 0s .4s;

    html.is-ready & {
        opacity: 1;
        transition: none;
    }
}

.l-nav {
    top: var(--grid-padding);
    right: var(--grid-padding);

    display: flex;
    align-items: center;
    opacity: 0;
    transform: translate(0, -200%);
    transition: opacity $speed-slow $easing, transform $speed-slow $easing;

    html.is-loading & {
        transform: translate(0, 200%);
    }

    html.is-ready & {
        opacity: 1;
        transform: translate(0);
    }
}

.l-nav_link {
    display: inline-block;
    margin-left: var(--grid-padding);
    text-transform: uppercase;
    overflow: hidden;
    transition: transform $speed $in-easing;

    .l-nav.is-hidden & {
        pointer-events: none;
        transform: translate(0, 50%);
    }

    @for $i from 1 through 4 {

        &:nth-child(#{$i}) {
            $delay: #{($i - 1)/4}s;

            .l-nav:not(.is-hidden) & {
                transition: transform $speed $out-easing $delay;
            }
        }
    }
}

.l-nav_label {
    display: block;
    transition: inherit;

    .l-nav.is-hidden & {
        transform: translate(0, -100%);
    }
}


