
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.l-wrapper {
    color: var(--root-color, #{$color-dark});
    background-color: var(--root-bg, #{$color-light});

    html.is-ready & {
        transition: color $speed $easing, background-color $speed $easing;
    }
}


.l-main {
    min-height: vh(100);
    opacity: 0;
    transform: translate(0, vh(-20));
    transition: opacity var(--loader-duration-out) var(--loader-ease-out), transform var(--loader-duration-out) var(--loader-ease-out);

    html.is-loading & {
        transform: translate(0, vh(20));
        transition: opacity var(--loader-duration-in) var(--loader-ease-in), transform var(--loader-duration-in) var(--loader-ease-in);
    }

    html.is-ready & {
        opacity: 1;
        transform: translate(0);
    }
}

