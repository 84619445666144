
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.o-categories {
    display: flex;
    flex-wrap: wrap;
    gap: calc(0.5 * var(--unit));
    @include reset-list;
}

