
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


/*----------  Mixin  ----------*/
@mixin squeeze-box-disable {

    .c-squeeze-box_container {
        height: auto;
        animation: none !important;
    }

    .c-squeeze-box_content {
        opacity: 1;
        transition: none !important;
    }
}

/*----------  Styles  ----------*/

.c-squeeze-box {
    --squeeze-box-container-height    : 0;
    --squeeze-box-easing              : #{$in-out-expo};
    --squeeze-box-duration            : 1s; // Must be in seconds
}

.c-squeeze-box_toggler {
    cursor: pointer;
    padding-right: 1em;
}

.c-squeeze-box_icon {
    position: absolute;
    right: 0;
    bottom: .2em;
    display: inline-block;
    width: .6em;
    height: .6em;
    transition: transform $speed $easing;

    &:after,
    &:before {
        content: "";
        position: absolute;
        background-color: currentColor;
    }

    &:before {
        top: 0;
        left: calc(50% - 2px/2);
        width: 2px;
        height: 100%;
    }

    &:after {
        left: 0;
        top: calc(50% - 2px/2);
        width: 100%;
        height: 2px;
        transition: inherit;
    }

    .c-squeeze-box.is-open & {
        transform: rotate(90deg);

        &:after {
            transform: rotate(90deg);
        }
    }
}

.c-squeeze-box_container {
    height: 0;
    overflow: hidden;
    animation: anim-squeeze-box-close var(--squeeze-box-duration) var(--squeeze-box-easing) forwards;

    .c-squeeze-box.is-open > & {
        animation: anim-squeeze-box-open var(--squeeze-box-duration) var(--squeeze-box-easing) forwards;
    }
}

.c-squeeze-box_content {
    padding-top: spacer(2);
    opacity: 0;
    transition: opacity calc(0.5 * var(--squeeze-box-duration)) ease-out;

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    .c-squeeze-box.is-open > .c-squeeze-box_container & {
        opacity: 1;
        transition: opacity calc(0.5 * var(--squeeze-box-duration)) ease-out calc(0.5 * var(--squeeze-box-duration));
    }
}


/*----------  Animations  ----------*/

@keyframes anim-squeeze-box-open {
    0% {
        height: 0;
    }
    99.99% {
        height: var(--squeeze-box-container-height);
    }
    100% {
        height: auto;
    }
}

@keyframes anim-squeeze-box-close {
    0% {
        height: var(--squeeze-box-container-height);
    }
    100% {
        height: 0;
    }
}

