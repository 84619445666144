
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.c-home-intro {
    --mouse-x: 0;
    --mouse-y: 0;

    padding-top: calc(.5 * var(--header-height));
    padding-bottom: calc(.5 * var(--header-height));
    counter-reset: home-intro-deco;

    display: flex;
    flex-wrap: wrap;
    min-height: vh(100);
}

.c-home-intro_title {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    padding-top: .5em;
    padding-bottom: .5em;

    u,
    strong {
        bottom: .1em;
        display: inline-block;
        vertical-align: middle;
    }

    i {

        .o-at_w {
            padding-right: .2em;
        }

        & + * {
            margin-left: -.2em;;
        }
    }

    sup {
        top: -.65em;
        display: inline-block;
        padding-right: .3em;
        padding-left: .5em;
        font-size: .3em;
        font-weight: 400;
    }

    span {
        display: inline-block;
    }

    .o-at_l {
        overflow: visible;

        &:nth-child(2) {
            padding-left: .1em;
        }
    }

    @media #{md("xs", "max")} {
        letter-spacing: -.01em;
    }
}

.c-home-intro_deco {
    counter-increment: home-intro-deco;
    width: 12vmax;

    &.-one {
        margin-top: auto;
        margin-left: grid-space(7/12);
        transform: scale(0) rotate(-90deg);
    }

    &.-two {
        margin-left: grid-space(1/12);
        margin-bottom: auto;
        transform: scale(0) rotate(90deg);
    }

    &.-three {
        margin-right: grid-space(2/12);
        margin-left: auto;
        margin-bottom: auto;
        transform: scale(0) rotate(-60deg);
    }

    html.is-ready & {
        transform: scale(1) rotate(0);
        transition: transform $speed-slow $easing;

        &.-one {
            transition-delay: .2s;

            .c-home-intro_caption {
                transition-delay: calc(.2s + $speed-medium);
            }
        }

        &.-two {
            transition-delay: 1s;

            .c-home-intro_caption {
                transition-delay: calc(1s + $speed-medium);
            }
        }

        &.-three {
            transition-delay: 1.4s;

            .c-home-intro_caption {
                transition-delay: calc(1.4s + $speed-medium);
            }
        }
    }
}

.c-home-intro_figure {

    .c-home-intro_deco.-one & {
        transform: translate(calc(var(--mouse-x) * -15%), calc(var(--mouse-y) * 20%));
    }

    .c-home-intro_deco.-two & {
        transform: translate(calc(var(--mouse-x) * 30%), calc(var(--mouse-y) * -30%));
    }

    .c-home-intro_deco.-three & {
        transform: translate(calc(var(--mouse-x) * -30%), calc(var(--mouse-y) * -25%));
    }
}

.c-home-intro_img {
    display: block;

    .c-home-intro_deco.-one & {
        transform: rotate(-6deg);
    }

    .c-home-intro_deco.-two & {
        transform: rotate(-8deg);
    }

    .c-home-intro_deco.-three & {
        transform: rotate(8deg);
    }
}

.c-home-intro_caption {
    position: absolute;
    display: inline-flex;
    align-items: baseline;
    font-family: ff("serif");
    font-style: italic;

    opacity: 0;
    transform: translate(0, 100%);
    transition: opacity $speed-slow $easing, transform $speed-slow $easing;

    &:after {
        content: counters(home-intro-deco, "", decimal-leading-zero);
        display: inline-block;
        margin-left: .2em;
        font-family: ff("sans");
        font-style: normal;
    }

    .c-home-intro_deco.-one & {
        top: 20%;
        right: calc(100% + .5em);
    }

    .c-home-intro_deco.-two & {
        top: 100%;
        left: 75%;
    }

    .c-home-intro_deco.-three & {
        bottom: 100%;
        left: calc(100% - 1em);
    }

    html.is-ready & {
        opacity: 1;
        transform: translate(0);
    }
}


