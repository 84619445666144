/*=========================================
=            Typographic bases            =
=========================================*/

// Include fontface
@include fontfaces($fontfaces, $font-dir);

body {
    font-family: ff("sans");
    line-height: $line-height;
}


/*==============================
=            Titles            =
==============================*/

.t-h1,
.t-cms h1,
.t-h2,
.t-cms h2,
.t-h3,
.t-cms h3,
.t-h4,
.t-cms h4 {
    line-height: 1.08;
    text-transform: uppercase;

    &.-lower {
        text-transform: none;
    }

    strong,
    &.-primary {
        color: var(--root-color-primary);
    }

    i,
    em,
    .t-highlight {
        font-family: ff("serif");
        font-style: italic;
        font-weight: 200;
        text-transform: lowercase;
    }

    u,
    strong {
        font-weight: inherit;
        text-decoration: none;
        text-transform: uppercase;
    }
}

.t-h1,
.t-cms h1 {
    //@include responsive-type(32, 92, 560, 1900);
    font-size: clamp(32px, calc(72/1600 * 100vw), 96px);
    font-weight: 400;
}

.t-h2,
.t-cms h2 {
    // @include responsive-type(26, 45, 480, 1600);
    font-size: clamp(26px, calc(45/1600 * 100vw), 45px);
    font-weight: 400;
}

.t-h3,
.t-cms h3 {
    // @include responsive-type(20, 32, 480, 1600);
    font-size: clamp(20px, calc(32/1600 * 100vw), 32px);
    font-weight: 400;
}

.t-h4,
.t-cms h4 {
    font-size: 1.5em;
    font-weight: 400;
}

.t-body {

    @media #{md("lg", "max")} {
        font-size: 16px;
    }
}

.t-body-big {
    font-size: 1.5em;
}

.t-content {

    > *:nth-child(n+2) {
        margin-top: 1rem;
    }
}

.t-link {
    display: inline-block;
    line-height: 1.1;

    &:not(.o-at),
    &.o-at.-lines .o-at_l,
    &.o-at.-words .o-at_w {
        transition: transform 1s $easing;
        transform-origin: 0 100%;
        will-change: transform;
    }

    &:hover {

        &:not(.o-at),
        &.o-at.-lines .o-at_l,
        &.o-at.-words .o-at_w {
            transform: skewX(-12deg) !important;
        }
    }
}

.t-small {
    font-size: .875em;
}

/*==========================================
=            CMS content styles            =
==========================================*/


.t-cms {

    &.-serif {
        font-family: ff("serif");
    }

    &.-small {
        font-size: .875em;
    }

    p,
    ul,
    ol,
    blockquote {
        margin-bottom: $line-height * 1em;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        margin: 1.414em 0 0.5em;
    }

    a {
        color: $color-primary;
        text-decoration: underline;
        cursor: pointer;
        transition: opacity $speed ease-out;

        &:hover {
            opacity: .7;
        }
    }

    ul,
    ol {
        @include reset-list;

        li {
            padding-left: 2em;
            margin-bottom: .5em;

            &:before {
                position: absolute;
            }
        }
    }

    ol {
        counter-reset: ol;

        li {
            counter-increment: ol;

            &:before {
                content: counter(ol) ".";
                top: 0;
                right: calc(100% - 2em);
                width: 2em;
                padding-right: .5em;
                text-align: right;
            }
        }
    }

    ul {

        li {

            &:before {
                content: "";
                top: .4em;
                left: 0;
                width: 1.2em;
                height: calc(1.2em * 10/18);
            }
        }
    }

    img {
        max-width: 100%;
    }

    b,
    strong {
        font-weight: 700;
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}


/*=====================================
=            Miscellaneous            =
=====================================*/

sup {
    top: -.2em;
    display: inline-block;
    padding-left: .1em;
    font-size: .5em;
    vertical-align: super;
}

a {
    color: inherit;
    text-decoration: none;
}

address {
    font-style: normal;
}
