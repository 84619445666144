
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


.o-vimeo-player {

    &:not(.-controls) {
        pointer-events: none;
    }

    &.-controls {

        &,
        & > * {
            cursor: pointer !important;
        }
    }

    &.-cover {
        overflow: hidden;

        > * {
            position: absolute;
            top: 50%;
            left: 50%;
        }
    }
}

.o-vimeo-player_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba($color-darkest, .5);
    }

    &.is-hidden {
        pointer-events: none;
        opacity: 0;
        transition: opacity $speed $easing;
    }
}

