
                    @use 'sass:math';
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


* {
    cursor: none !important;
}

.o-cursor {
    --cursor-color: currentColor;
    --cursor-size: 4em;

    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: var(--cursor-size);
    height: var(--cursor-size);
    margin-top: calc(-0.5 * var(--cursor-size));
    margin-left: calc(-0.5 * var(--cursor-size));
    pointer-events: none;
    transition: color $speed $easing;
    will-change: transform;

    @media (hover: none) {
        display: none;
        visibility: hidden;
    }

    &.-loading {
    }
}

.o-cursor_inner,
.o-cursor_loading {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.o-cursor_inner {
    background-color: var(--cursor-color);
    transform: scale(.2);
    transition: opacity $speed $easing, transform $speed $easing;
    will-change: transform;

    .o-cursor.-click & {
        opacity: .5;
        transform: scale(.5);
    }

    .o-cursor.-hide &,
    .o-cursor.-loading & {
        transform: scale(0);
    }
}

.o-cursor_loading {
    opacity: 0;
    transform: scale(1.2);
    transition: opacity $speed $easing, transform $speed $easing;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        stroke: var(--theme-color-primary);
        fill: none;
        animation: anim-cursor-loader-rotate 2s linear infinite paused;
    }

    circle {
        stroke-dasharray: 150,200;
        stroke-dashoffset: -10;
        animation: anim-cursor-loader-dash 1.5s ease-in-out infinite paused;
        stroke-linecap: round;
    }

    .o-cursor.-loading & {
        transform: scale(1);
        opacity: 1;

        svg,
        circle {
            animation-play-state: running;
        }
    }
}


/*----------  Animations  ----------*/

@keyframes anim-cursor-loader-rotate {
    100% {
        transform: rotate(1turn);
    }
}

@keyframes anim-cursor-loader-dash {
    0% {
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124;
    }
}



